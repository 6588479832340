<template>
	<div class="centent">
		<div class="tj_title">
			投教订单
		</div>
		<table>
			<tr>
				<th>
					订单编号
				</th>
				<th>
					专辑名称
				</th>
				<th>
					实付金额
				</th>
				<th>
					服务开始时间
				</th>
				<th>
					服务结束时间
				</th>
				<th>
					操作
				</th>
			</tr>
			<tr v-for="(item,index) in dataList" :key="index">
				<td>
					{{item.orderCode}}
				</td>
				<td>
					{{item.serviceProdName}}
				</td>
				<td>
					{{item.actualPrice/100}}
				</td>
				<td>
					{{item.createDtime}}
				</td>
				<td>
					{{item.endDtime}}
				</td>
				<td>
					<a href="javascript:;" @click="openVideo(item.serviceProdId)">专辑详情&nbsp;&nbsp;</a>
					<a v-for="(itemTwo,indexTwo) in item.menu" :key="indexTwo" :href="itemTwo.url.split('https:')[1]">{{itemTwo.name}}&nbsp;&nbsp;</a>
				</td>
			</tr>
		</table>
		<div class="no_order" v-if="dataList.length == 0">
			暂无订单
		</div>
	</div>
</template>
<script>
	export default {
    data(){
        return{
            dataList:[]
        }
    },
    created() {
		this.postRequest("/sale/service/list.wp",{licenceId:11}).then(res=>{
           if(res.status == 200){
               this.dataList = res.data.list
           }
        })
    },
	methods:{
		openVideo:function(albumId){
			var url = "/video/index.html?albumId="+albumId
			window.open(this.allFn.getUrlVerify(url))
		}
	}
}
</script>
<style scoped lang="scss" type="text/css">
	.centent {
		width: 100%;
		min-height: 500px;
		background: #fff;
		border-radius: 5px;
	}

	.centent .tj_title {
		text-align: left;
		color: #333333;
		font-size: 16px;
		padding: 22px 0 22px 22px;
		border-bottom: 1px solid #D2D2D2;
	}

	.centent table {
		text-align: center;
		border-collapse: collapse;
	}

	table {
		width: 900px;
		margin-top: 20px;
		margin-left: 30px;
	}

	table th {
		background-color: #F2F2F2;
		color: #4D9DFC;
		font-weight: 400;
		font-size: 16px;
	}

	table,
	table tr th,
	table tr td {
		border: 1px solid #D2D2D2;
		height: 50px;
	}

	table a {
		color: #4D9DFC;
	}

	.centent .no_order {
		text-align: center;
	}
</style>
